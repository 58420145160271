// Custom.scss
// Option B: Include parts of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
// Configuration
@import "../node_modules/bootstrap/scss/functions"; // Required

// 2. Include any default variable overrides here

// Toggle global options
$enable-gradients: true;
$enable-shadows: true;

// Customize some defaults
$body-color: #333;
$body-bg: #fff;
$border-radius: .4rem;
$success: #7952b3;

// 3. Include remainder of required Bootstrap stylesheets
// Configuration
@import "../node_modules/bootstrap/scss/variables"; // Required
@import "../node_modules/bootstrap/scss/mixins"; // Required

// 4. Include any optional Bootstrap CSS as needed
// Layout & components
@import "../node_modules/bootstrap/scss/root"; // Required
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/reboot"; // Required
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/helpers";
@import "../node_modules/bootstrap/scss/nav"; // Requires nav
@import "../node_modules/bootstrap/scss/navbar"; // Requires nav
@import "../node_modules/bootstrap/scss/carousel";
@import "../node_modules/bootstrap/scss/modal"; // Requires transitions

// 5. Optionally include utilities API last to generate classes based on the Sass map in `_utililies.scss`
@import "../node_modules/bootstrap/scss/utilities/api";

// 6. Add additional custom code here
//
// Custom styles
//

// @import "icon-list";

// body {
//   padding: 3rem 1.5rem;
// }

// // Style Bootstrap icons
// .bi {
//   fill: currentColor;
// }